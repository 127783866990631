import { colorChangeSampleService } from '@/api';
import {} from '@/resource/model';
import { TagsViewModule } from '@/store/modules/tags-view';
import { messageError, translation } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';

import { ColorChangeSampleInfo } from '@/resource/model/prepress-task/prepress-task';
import { PrepressChangeColorCraftsEnum, PrepressSampleStatusEnum } from '@/resource/enum';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'ColorChangeSampleDetails',
  components: {}
})
export default class ColorChangeSampleDetails extends Vue {
  /**
   *  印前任务ID
   */
  public id = Number(this.$route.query.id);

  public form: Partial<ColorChangeSampleInfo> = {
    id: undefined,
    itemCode: '',
    /**
     * 后道工艺
     */
    backendCrafts: '',
    /**
     * 后道流程 1 制作 0 不制作
     */
    backendFlag: 0,
    /**
     * 项次数量
     */
    count: 0,
    /**
     * 客户产品id
     */
    customerProductId: 0,
    /**
     * 客户产品名称
     */
    customerProductName: '',
    /**
     * 完成高
     */
    finishHeight: 0,
    /**
     * 完成宽
     */
    finishWidth: 0,
    /**
     * 单据类型
     */
    itemType: 0,
    /**
     * 制作品名id
     */
    platformProductId: 0,
    /**
     * 制作品名
     */
    platformProductName: '',
    /**
     * 印前工艺
     */
    prepressCrafts: 0,
    /**
     * 印前画面
     */
    prepressPicture: '',
    /**
     * 印前说明
     */
    prepressRemark: '',
    /**
     * 输出流程 1 制作 0 不制作
     */
    printingFlag: 0,
    /**
     * 项目编号
     */
    projectCode: '',
    /**
     * 项目名称
     */
    projectName: '',
    /**
     * 备注
     */
    remark: '',
    /**
     * 要求出货时间
     */
    requiredDeliveryTime: '',
    /**
     * 可见高
     */
    visibleHeight: 0,
    /**
     * 可见宽
     */
    visibleWidth: 0,
    /**
     * 轮次
     */
    round: undefined,
    /**
     * 单位面积
     */
    unitArea: 0,
    /**
     * 总面积
     */
    totalArea: 0,

    status: undefined,
    createUserName: '',
    createTime: '',
    updateUserName: '',
    updateTime: ''
  };

  public get getStatus(): string {
    if (!this.form.status) {
      return translation('common.unKnownStatus');
    }
    return translation(`prepressSampleStatus.${PrepressSampleStatusEnum[this.form.status]}`);
  }
  public get screenArea(): string {
    return ((this.form!.finishWidth! / 1000) * (this.form!.finishHeight! / 1000)).toFixed(6);
  }

  public get screenRate(): string {
    return (this.form!.visibleWidth! / this.form!.visibleHeight!).toFixed(3);
  }

  public get prepressCrafts(): string {
    return translation(`prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[this.form!.prepressCrafts!]}`);
  }

  public activated(): void {
    // 当id变化时，重新加载数据
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);

      this.getChangeColorSampleInfo(this.id);
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.getChangeColorSampleInfo(this.id);
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    TagsViewModule.DelView(this.$route);
    next();
  }
  private getChangeColorSampleInfo(id: number): void {
    colorChangeSampleService
      .getById(id)
      .then(res => {
        Object.assign(this.form, res);
      })
      .catch(error => {
        messageError(error);
      });
  }
}
